<template>
  <container class="contacts" fluid>
    <v-card>
      <v-card-title>{{
        __('Controle de contatos', 'contact')
      }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-text-field
              v-model="filter"
              :label="capitalize($tc('model.interface_search_items', 1))"
              prepend-icon="mdi-magnify"
              @keyup.enter="getContacts"
              :disabled="loading"
              clearable
            ></v-text-field>
          </v-col>
          <v-col class="d-none d-sm-flex align-center justify-end">
            <v-btn icon @click="getContacts()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <ScopeProvider scope="contacts.add">
              <v-btn to="/contacts/new" color="primary">{{
                __('Novo contato', 'contact')
              }}</v-btn>
            </ScopeProvider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="contacts"
        :items-per-page="5"
        class="elevation-1"
        :disabled="loading"
        :options.sync="options"
        :server-items-length="totalContacts"
        :loading="loading"
        :loading-text="__('Carregando', 'contact')"
        fixed-header
        max-height="500"
        :footer-props="{
          itemsPerPageOptions: [
            5,
            10,
            15,
            50
          ]
        }"
      >
        <template v-slot:header.name>
          {{ __('Nome', 'contact') }}
        </template>
        <template v-slot:header.type>
          {{ __('Tipo', 'contact') }}
        </template>
        <template v-slot:header.fiscal_number>
          {{ __('Documento fiscal', 'contact') }}
        </template>
        <template v-slot:header.email>
          {{ __('Email', 'contact') }}
        </template>
        <template v-slot:header.contact1>
          {{ __('Telefone principal', 'contact') }}
        </template>
        <template v-slot:header.actions>
          {{ capitalize($tc("model.interface_action", 2)) }}
        </template>
        <template v-slot:item.type="{ item }">
          <v-chip v-if="item.type == 'F'">{{
            __('Pessoa física', 'contact')
          }}</v-chip>
          <v-chip v-else-if="item.type == 'J'" icon="mdi-account">
            {{ __('Pessoa jurídica', 'contact') }}
          </v-chip>
          <v-chip v-else>{{ item.type }}</v-chip>
        </template>

        <template v-slot:item.email="{ item }">
          <span :class="{ 'grey--text': !item.email }">{{
            item.email || "N/A"
          }}</span>
        </template>

        <template v-slot:item.fiscal_number="{ item }">
          <span :class="{ 'grey--text': !item.fiscal_number }">{{
            item.fiscal_number || "N/A"
          }}</span>
        </template>

        <template v-slot:item.contact1="{ item }">
          <v-chip icon="mdi-phone">
            <v-avatar left>
              <v-icon small>mdi-phone</v-icon>
            </v-avatar>
            {{ item.contact1 }}
          </v-chip>
          <v-chip v-if="item.contact2" icon="mdi-phone" class="ml-1">
            <v-avatar left>
              <v-icon small>mdi-phone</v-icon>
            </v-avatar>
            {{ item.contact2 }}
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn @click="handleClickEditContact(item.id)" icon>
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="verifyScope('contacts.delete')"
            @click="handleClickDeleteContact(item.id)"
            icon
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-btn
      to="/contacts/new"
      color="primary"
      fab
      fixed
      bottom
      right
      class="d-sm-none"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" max-width="600px">
      <contact-form
        @cancel="handleCancelDialog"
        @submit="handleSubmitDialog"
        :id="contact_id"
      ></contact-form>
    </v-dialog>

    <v-dialog v-model="newContactDialog" max-width="600px" scrollable>
      <!-- <new-contact-form
        @cancel="newContactDialog = false"
        @submit="handleSubmitDialog"
        :id="contact_id"
      ></new-contact-form>-->
      <v-card>
        <v-card-text style="height: 900px">aadsd</v-card-text>
      </v-card>
    </v-dialog>

    <confirm-action-dialog
      :confirm_action="confirmation"
      @change="confirmation = $event"
      @action="confirmDelete()"
      :message="__('O contato será permanentemente excluído', 'contact')"
    ></confirm-action-dialog>
  </container>
</template>

<script>
import ContactForm from "../components/forms/ContactForm";
import NewContactForm from "../components/forms/NewContactForm";
import ConfirmActionDialog from "../components/ConfirmActionDialog";
// import ContactProfile from "../components/ContactProfile";
import ScopeProvider from "@/components/ScopeProvider";
import i18n from '@/mixins/i18n';

export default {
  components: { ContactForm, ConfirmActionDialog, ScopeProvider },
  mixins: [i18n],
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Nome",
        value: "name",
      },
      {
        text: "Entidade",
        value: "type",
      },
      {
        text: "Nif",
        value: "fiscal_number",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Contactos",
        value: "contact1",
        sortable: false,
      },
      {
        text: "Acções",
        value: "actions",
        align: "right",
        sortable: false,
      },
    ],
    contacts: [],
    loading: false,
    options: {
      itemsPerPage: 10,
      sortBy: ["name"],
      sortDesc: [],
    },
    totalContacts: 0,
    filter: "",
    contact_id: null,
    confirmation: false,
    delete_loading: false,
    delete_contact_id: null,
    newContactDialog: false,
  }),
  methods: {
    verifyScope(scope) {
      return this.$store.getters["auth/getScope"](scope);
    },
    fetchContacts() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      return this.$http.get(
        `/contacts?page=${page}&perPage=${itemsPerPage}&orderBy=${
          sortBy[0] ? sortBy[0] : ""
        }&sortDesc=${sortDesc[0] ? sortDesc[0] : ""}&filter=${
          this.filter || ""
        }`
      );
    },
    async getContacts() {
      this.contacts = [];
      this.loading = true;
      const response = await this.fetchContacts();
      const data = response.data;
      this.contacts = data.data;
      this.totalContacts = data.total;
      this.loading = false;
    },
    handleClickEditContact(id) {
      this.contact_id = id;
      this.dialog = true;
    },
    handleClickNewContact() {
      this.newContactDialog = true;
    },
    handleClickDeleteContact(id) {
      this.delete_contact_id = id;
      this.confirmation = true;
    },
    handleCancelDialog() {
      this.contact_id = null;
      this.dialog = false;
    },
    handleSubmitDialog() {
      this.contact_id = null;
      this.dialog = false;
      this.getContacts();
    },
    deleteContact(id) {
      return this.$http.delete(`/contacts/${id}`);
    },
    async confirmDelete() {
      this.delete_loading = true;

      try {
        await this.deleteContact(this.delete_contact_id);
        this.$store.commit("sendMessage", {
          text: this.__('Contato excluído com sucesso', 'contact'),
        });
        this.contacts = this.contacts.filter(
          (contact) => contact.id !== this.delete_contact_id
        );
        this.confirmation = false;
      } catch (e) {
        this.$store.commit("sendMessage", {
          text: e.response.data.message,
          color: "red",
        });
      } finally {
        this.delete_loading = false;
      }
    },
    capitalize: function (s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
  },
  watch: {
    options: {
      handler() {
        this.getContacts();
      },
      deep: true,
    },
    filter: function (value, old_value) {
      //Atualiza quando o usuário limpar o filtro de busca
      if (value === null) this.getContacts();
    },
  },
  created() {
    this.getContacts();
  },
};
</script>

<style lang="scss">
.contacts {
}
</style>
